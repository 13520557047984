.packageCardContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.packagesTableContainer {
  width: 75%;
  padding: 0 25px 25px 25px;
  @media (max-width: 992px) {
    padding: 10px;
  }
  background-color: white;
  border-radius: 10px;
  box-shadow: var(--box-shadow-vx-med);
}

.uploadSystem {
  cursor: pointer;
}

.uploadSystem:focus {
  outline: none;
}
