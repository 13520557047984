.cardContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin: 0 auto;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 40px;
  @media (max-width: 979px) {
    padding: 40px 0px;
  }
  min-height: 100%;
  @media (max-width: 916px) {
    align-items: center;
    flex-direction: column-reverse;
  }
}

.subButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.subscribeButton {
  margin: 0 auto;
  position: absolute;
  max-width: 400px;
  --box-shadow: var(--box-shadow-vx-med);
  --border-radius: 10px;
}

#modalContainer {
  width: 90%;
  margin: 0 auto;
}
