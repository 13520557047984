.Menu-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 12px;
  flex-direction: column;
  h3 {
    width: calc(100% - 32px);
    padding-bottom: 9px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 10px;
  }
}

.adminNavLinks {
  margin-bottom: 15px;
}

ion-icon {
  padding-right: 10px;
}

.split-pane-ios.split-pane-visible > .split-pane-side {
  width: 210px;
  max-width: 250px;
  min-width: 250px;
}
