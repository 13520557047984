.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
  p {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    opacity: 0.3;
    margin: 0;
    padding-right: 8px;
  }
}

.ion-footer-z-fix {
  z-index: -1;
}
