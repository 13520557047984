.notificationCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.admin__notification_container {
  width: 500px;
  height: 465px;
  padding: 0 25px;
  background-color: white;
  border-radius: 10px;
  box-shadow: var(--box-shadow-vx-med);
  overflow: hidden;
}

.sendButton {
  margin: 25px 0;
}
