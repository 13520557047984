ion-header {
  --border-bottom: 0px;
}

.header-md:after {
  background-image: none;
}

.screen-bg {
  --background: transparent;
}

.menu-ion-content {
  --background: transparent;
  @media (max-width: 991px) {
    display: initial;
    --background: #fafafa;
  }
}

body {
  background: var(--ion-color-vxmed-bg);
}

ion-app {
  background-image: url('../assets/BackgroundImg.svg');
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}

ion-menu {
  --background: transparent;
}

ion-split-pane {
  --border: 0px !important;
}

.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
