.card {
  max-width: 320px;
  flex: 1;
  min-width: 270px;
  max-height: 560px;
  transition: 0.3s;
  text-align: center;
  border-radius: var(--border-radius-vx-med);
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 24px 8px;
  background: white;
  box-shadow: var(--box-shadow-vx-med);
  padding: 16px;
  @media (max-width: 916px) {
    width: calc(100% - 16px);
  }
}

.toastMessage {
  --background: #222;
  font-size: 18px;
  --color: white;
  text-align: center;
}

.title {
  color: var(--ion-color-medium-shade);
  font-size: 1.3rem;
  margin: 0;
}

.cost {
  font-size: 2.5rem;
  margin: 0;
  padding-top: 19px;
  color: #696969;
}

.line {
  border: 1px 0px solid var(--ion-color-vxmed-primary);
  background: var(--ion-color-vxmed-primary);
  width: 50%;
  margin: 25px auto;
}

.perks {
  font-size: 1.1rem;
  width: 90%;
  margin: 0 auto;
  flex: 1;
}

p {
  margin: 0 0 10px 0;
}
