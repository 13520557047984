.launchPageBody {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.download-launcher-button {
  --box-shadow: var(--box-shadow-vx-med);
  --border-radius: 10px;
  width: 80%;
  max-width: 400px;
}

.launch-button {
  width: 80%;
  max-width: 400px;
  box-shadow: var(--box-shadow-vx-med);
  border-radius: 10px;
  background: var(--ion-color-primary);
  display: flex;
  justify-content: center;
  background: var(--ion-color-primary);
  align-items: stretch;
  cursor: pointer;
  overflow: hidden;
  height: 62px;
  .launch-button-text {
    color: white;
    font-size: 22px;
    font-weight: 400;
    flex: 1;
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &:hover {
      background: var(--ion-color-primary-shade);
    }
  }
  .launch-button-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .launch-button-selected {
    border-left: 1px solid rgba(20, 20, 20, 0.377);
    color: white;
    font-size: 18px;
    font-weight: 400;
    padding: 10px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    ion-icon {
      transform: translateY(4px);
      font-size: 22px;
      padding-right: 0;
      padding-left: 6px;
    }
    &:hover {
      background: var(--ion-color-primary-shade);
    }
  }
}
.version-p {
  opacity: 0.7;
  font-weight: 700;
  letter-spacing: 0.3px;
}

.install-alert {
  color: #b61010;
  margin-top: -16px;
  text-align: center;
  font-weight: 700;
}
