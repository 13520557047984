.signupFormContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.messageToast {
  text-align: center;
  font-size: 1.2rem;
}

.settings-form-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-height: 900px) {
    margin-top: 70px;
  }
}

.settings-form {
  max-width: 500px;
  width: 100%;
  padding: 0 25px;
  @media (max-width: 600px) {
    padding: 16px;
  }
  background-color: white;
  border-radius: var(--border-radius-vx-med);
  box-shadow: var(--box-shadow-vx-med);
}

.userFormBodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.userInfoContainer,
.passwordResetContainer {
  width: 100%;
  align-self: center;
}

.subTitle {
  text-align: center;
}

.settings-button {
  margin: 25px 0;
  --background: var(--ion-color-vxmed-primary);
  --background-hover: var(--ion-color-vxmed-primary);
  color: var(--ion-color-medium-contrast);
}

.settingsItem {
  --highlight-color-focused: var(--ion-color-accent);
}
