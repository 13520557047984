.loginContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.errorToastLogin {
  text-align: center;
  font-size: 1.2rem;
}

.login-form {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  padding: 15px 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: var(--box-shadow-vx-med);
}

.login-button {
  margin: 50px 0 50px 0;
  --background: var(--ion-color-vxmed-primary);
  --background-hover: var(--ion-color-vxmed-primary);
  color: var(--ion-color-medium-contrast);
}

.loginItem {
  --highlight-color-focused: var(--ion-color-accent);
}
