/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3f51b5;
  --ion-color-primary-rgb: 63, 81, 181;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #37479f;
  --ion-color-primary-tint: #5262bc;

  --ion-color-secondary: #303f9f;
  --ion-color-secondary-rgb: 48, 63, 159;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #2a378c;
  --ion-color-secondary-tint: #4552a9;

  --ion-color-tertiary: #ffc107;
  --ion-color-tertiary-rgb: 255, 193, 7;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e0aa06;
  --ion-color-tertiary-tint: #ffc720;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-accent: #ffc107;
  --ion-color-vxmed-primary: #3f51b5;
  --ion-color-vxmed-primary-dark: #303f9f;

  /* --ion-color-vxmed-bg: linear-gradient(-30deg, rgba(48, 63, 159, 1) 0%, rgba(63, 81, 181, 1) 100%); */
  --ion-color-vxmed-bg: linear-gradient(180deg, #fafafa 0%, rgb(216, 216, 216) 100%);
  /* --ion-color-vxmed-bg: #fafafa; */

  --box-shadow-vx-med: 0px 2px 11px rgba(6, 20, 25, 0.07), 0px 18px 35px rgba(6, 20, 25, 0.1);
  --border-radius-vx-med: 10px;
}

:root {
  --ion-color-primary-light: #c5cae9;
  --ion-color-primary-light-rgb: 197, 202, 233;
  --ion-color-primary-light-contrast: #000000;
  --ion-color-primary-light-contrast-rgb: 0, 0, 0;
  --ion-color-primary-light-shade: #adb2cd;
  --ion-color-primary-light-tint: #cbcfeb;
}

.ion-color-primary-light {
  --ion-color-base: var(--ion-color-primary-light);
  --ion-color-base-rgb: var(--ion-color-primary-light-rgb);
  --ion-color-contrast: var(--ion-color-primary-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-light-shade);
  --ion-color-tint: var(--ion-color-primary-light-tint);
}
