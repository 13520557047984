.resetContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-form {
  min-width: 500px;
  margin: 0 auto;
  padding: 50px;
  background-color: white;
  border-radius: var(--border-radius-vx-med);
}

.reset-button {
  margin: 50px 0 50px 0;
}

.resetItem {
  --highlight-color-focused: var(--ion-color-accent);
}
