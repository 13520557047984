.errorToastUploadModal {
  text-align: center;
  font-size: 1.2rem;
}

.uploadModal {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
}

.uploadProgressBarContainer {
  padding: 20px;
  height: 100%;
  width: 100%;
}

.progressBar {
  top: 50%;
}

.modalCloseButton {
  align-self: flex-end;
  font-size: 40px;
}

.modalBody {
  height: inherit;
  width: inherit;
}

.uploadArea {
  height: 60%;
  width: 100%;
  border: 1px dashed #424242;
  background-color: #d4d4d4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadDataContainer,
.uploadSystem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadSystem {
  width: 100%;
  height: 100%;
}

.versionPlatformRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.rowItem {
  width: 50%;
}

.uploadAreaText {
  text-align: center;
}
