ion-header {
  --background: transparent;
  ion-toolbar {
    --background: transparent;
    --ion-toolbar-background: transparent;
  }
}

.ionHeaderElement {
  pointer-events: none;
}

.logoImage {
  width: 105px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.menu-icon {
  ion-button {
    display: none;
    @media (max-width: 991px) {
      display: initial;
    }
  }
  &.hide {
    display: none;
  }
}
