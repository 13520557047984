.errorToast {
  text-align: center;
  font-size: 1.2rem;
}

.signup-form {
  width: 550px;
  margin: 0 auto;
  padding: 25px;
  background-color: white;
  border-radius: var(--border-radius-vx-med);
  box-shadow: var(--box-shadow-vx-med);
  @media (max-height: 900px) {
    margin-top: 140px;
    margin-bottom: 10px;
  }
}

.signup-button {
  margin: 25px 0;
  --background: var(--ion-color-vxmed-primary);
  --background-hover: var(--ion-color-vxmed-primary);
  color: var(--ion-color-medium-contrast);
}

.signupItem {
  --highlight-color-focused: var(--ion-color-accent);
}

.signup-error-text {
  color: red;
  margin-left: 15px !important;
}
